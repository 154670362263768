import {BaseRequest} from "@/request/BaseRequest";

class IntegralSignInSet extends BaseRequest {

    public requestPath: string = '/marketing/integralSignInSet';

    info(): any {
        return this.get(`${this.requestPath}/info`)
    }

    update(data: {
        integralFirst: number,
        integralSecond: number,
        integralThird: number,
        integralFourth: number,
        integralFifth: number,
        integralSixth: number,
        integralSeventh: number
    }): any {
        return this.post(`${this.requestPath}/update`, data)
    }

}

const c = new IntegralSignInSet();
export {c as IntegralSignInSet};