


























































import {IntegralSignInSet} from "@/request/marketing/IntegralSignInSet";

export default {
    data() {
        return {
            loadingData: false,
            loading: false,
            editIndex: -1,
            editIntegralFirst: 0,
            editIntegralSecond: 0,
            editIntegralThird: 0,
            editIntegralFourth: 0,
            editIntegralFifth: 0,
            editIntegralSixth: 0,
            editIntegralSeventh: 0,
            columns: [
                {
                    title: '周一',
                    slot: 'integralFirst',
                    align: 'center',
                    minWidth: 100
                },
                {
                    title: '周二',
                    slot: 'integralSecond',
                    align: 'center',
                    minWidth: 100
                },
                {
                    title: '周三',
                    slot: 'integralThird',
                    align: 'center',
                    minWidth: 100
                },
                {
                    title: '周四',
                    slot: 'integralFourth',
                    align: 'center',
                    minWidth: 100
                },
                {
                    title: '周五',
                    slot: 'integralFifth',
                    align: 'center',
                    minWidth: 100
                },
                {
                    title: '周六',
                    slot: 'integralSixth',
                    align: 'center',
                    minWidth: 100
                },
                {
                    title: '周末',
                    slot: 'integralSeventh',
                    align: 'center',
                    minWidth: 100
                },
                {
                    title: '操作',
                    slot: 'action',
                    align: 'center',
                    minWidth: 130
                }
            ],
            data: []
        }
    },
    created() {
        let self: any = this;
        self.loadingData = true;
        IntegralSignInSet.info().then((body: any) => {
            self.loadingData = false;
            self.data.push({...body.data})
        })
    },
    methods: {
        handleEdit(row: any, index: number) {
            let self: any = this;
            self.editIndex = index;
            self.editIntegralFirst = row.integralFirst;
            self.editIntegralSecond = row.integralSecond;
            self.editIntegralThird = row.integralThird;
            self.editIntegralFourth = row.integralFourth;
            self.editIntegralFifth = row.integralFifth;
            self.editIntegralSixth = row.integralSixth;
            self.editIntegralSeventh = row.integralSeventh;
        },
        handleSave(index: number) {
            let self: any = this;
            self.data[index].integralFirst = self.editIntegralFirst;
            self.data[index].integralSecond = self.editIntegralSecond;
            self.data[index].integralThird = self.editIntegralThird;
            self.data[index].integralFourth = self.editIntegralFourth;
            self.data[index].integralFifth = self.editIntegralFifth;
            self.data[index].integralSixth = self.editIntegralSixth;
            self.data[index].integralSeventh = self.editIntegralSeventh;
            self.loading = true;
            IntegralSignInSet.update(self.data[index]).then((body: any) => {
                self.loading = false;
                if (body.code === 0) {
                    self.editIndex = -1;
                }
            })
        }
    }
}
